<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <BaseLayout />
    </template>
  </div>
</template>

<style>
#app {
}
</style>
<script setup>
import BaseLayout from "@/layouts/BaseLayout";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const doInit = () => {
  console.log("欢迎访问我的博客 https://argon.zzzyu.cn");
};
onMounted(() => {
  doInit();
});
</script>
